/**
 * Provides a date picker for Final Forms (using https://github.com/airbnb/react-dates)
 *
 * NOTE: If you are using this component inside BookingDatesForm,
 * you should convert value.date to start date and end date before submitting it to API
 */
import React, { Component } from 'react';
import { arrayOf, bool, func, object, shape, string, instanceOf } from 'prop-types';
import { isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import moment from 'moment';

import { useConfiguration } from '../../context/configurationContext';
import { ValidationError } from '../../components';

import DateInput from './DateInput';
import css from './FieldDateInput.module.css';
import PropTypes from 'prop-types';

const MAX_MOBILE_SCREEN_WIDTH = 768;



class FieldDateInputComponent extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange = (parentOnChange, inputOnChange) => value => {
    const { date: newDate } = value;
    if (!newDate) {
      return;
    }
    
    const currentValue = this.props.input.value || { daySlots: [] };
    if (currentValue.date && newDate.getTime() === currentValue.date.getTime()) {
      return;
    }
    
    const currentDaySlots = currentValue.daySlots || [];
  
    // Check if newDate is already in the array
    const dateExists = currentDaySlots.some(slot =>
      slot.date.getTime() === newDate.getTime()
    );

    let updatedDaySlots;
    if (dateExists) {
      // Remove the day object (unselect)
      updatedDaySlots = currentDaySlots.filter(
        slot => slot.date.getTime() !== newDate.getTime()
      );
    } else {
      // Add new day object
      updatedDaySlots = [
        ...currentDaySlots,
        {
          date: newDate,
          startTime: null,
          endTime: null,
        },
      ];
    }

    const newValue = { daySlots: updatedDaySlots };
    if (parentOnChange) parentOnChange(newValue);
    inputOnChange(newValue);
  };

  render() {
    const {
      className,
      rootClassName,
      id,
      label,
      showLabelAsDisabled,
      input,
      currentMonth,
      onNextMonthClick,
      onPrevMonthClick,
      meta,
      useMobileMargins,
      showErrorMessage,
      onChange: parentOnChange,
      ...rest
    } = this.props;

    if (label && !id) {
      throw new Error('id required when a label is given');
    }

    const { touched, invalid, error } = meta;
    const value = input.value;
    const daySlots = value.daySlots || [];

    // If startDate is valid label changes color and bottom border changes color too
    const dateIsValid = value && Array.isArray(value.daySlots) && value.daySlots.length > 0;
    // Error message and input error styles are only shown if the
    // field has been touched and the validation has failed.
    const hasError = touched && invalid && error;

    const inputClasses = classNames({
      [css.pickerSuccess]: dateIsValid,
      [css.pickerError]: hasError,
    });

    const { onBlur, onFocus, onChange: inputOnChange, type, checked, ...restOfInput } = input;
    const inputProps = {
      onBlur: input.onBlur,
      onFocus: input.onFocus,
      useMobileMargins,
      id,
      readOnly: false,
      ...restOfInput,
      ...rest,
    };
    const classes = classNames(rootClassName || css.fieldRoot, className);
    const errorClasses = classNames({ [css.mobileMargins]: useMobileMargins });

    return (
      <div className={classes}>
        {label ? (
          <label
            className={classNames({
              [css.mobileMargins]: useMobileMargins,
              [css.labelDisabled]: showLabelAsDisabled,
            })}
            htmlFor={id}
          >
            {label}
          </label>
        ) : null}
        <DateInput 
          className={inputClasses} 
          {...inputProps}
          highlightedDates={daySlots} 
          onChange={this.handleChange(parentOnChange, inputOnChange)}
          isDayBlocked={this.props.isDayBlocked}
          currentMonth={currentMonth}
          onNextMonthClick={onNextMonthClick}
          onPrevMonthClick={onPrevMonthClick}
        />
        {showErrorMessage ? <ValidationError className={errorClasses} fieldMeta={meta} /> : null}
      </div>
    );
  }
}

FieldDateInputComponent.defaultProps = {
  className: null,
  rootClassName: null,
  useMobileMargins: false,
  showErrorMessage: true,
  id: null,
  label: null,
  showLabelAsDisabled: false,
  placeholderText: null,
  onChange: null,
};

FieldDateInputComponent.propTypes = {
  className: string,
  rootClassName: string,
  useMobileMargins: bool,
  showErrorMessage: bool,
  id: string,
  label: string,
  showLabelAsDisabled: bool,
  placeholderText: string,
  input: shape({
    value: shape({
      daySlots: PropTypes.arrayOf(
        PropTypes.shape({
          date: PropTypes.instanceOf(Date),
          startTime: PropTypes.string,
          endTime: PropTypes.string,
        })
      ),
    }),
  }).isRequired,
  meta: object.isRequired,
  onChange: func,
  isDayBlocked: func,
};

const FieldDateInput = props => {
  const config = useConfiguration();
  const { isOutsideRange, firstDayOfWeek, ...rest } = props;

  // Outside range -><- today ... today+available days -1 -><- outside range
  const defaultIsOutSideRange = day => {
    const endOfRange = config.stripe?.dayCountAvailableForBooking - 1;
    return (
      !isInclusivelyAfterDay(day, moment()) ||
      !isInclusivelyBeforeDay(day, moment().add(endOfRange, 'days'))
    );
  };
  const defaultFirstDayOfWeek = config.localization.firstDayOfWeek;
  return (
    <Field
      component={FieldDateInputComponent}
      isOutsideRange={isOutsideRange || defaultIsOutSideRange}
      firstDayOfWeek={firstDayOfWeek || defaultFirstDayOfWeek}
      {...rest}
    />
  );
};

export { DateInput };
export default FieldDateInput;